import { initialStateResult, ResultState, stateError, stateLoading, stateSuccess } from '@mbp/angular/ngrx';
import {
    BrainlaneVoucherDto,
    GetOrderPaymentLinkResponseDto,
    GetUserDto,
    MerchantListDto,
    OrderSettingsDto,
    WalletCampaignListDto,
    WalletHistoryDto,
    WalletOrderDetailsDto,
    WalletOrderDto,
    WalletTransactionDetailsDto,
    WalletTransactionDto,
    WalletVoucherListDto,
} from '@mbp/mbp-brainlane-vouchers-api-client';
import { createFeature, createReducer, on } from '@ngrx/store';

import {
    convertVoucher,
    convertVoucherFailure,
    convertVoucherSuccess,
    createAccount,
    createAccountFailure,
    createAccountSuccess,
    createOrder,
    createOrderFailure,
    createOrderSuccess,
    getOrderPaymentLink,
    getOrderPaymentLinkFailure,
    getOrderPaymentLinkSuccess,
    getVoucherInfo,
    getVoucherInfoFailure,
    getVoucherInfoSuccess,
    loadCampaignsInWallet,
    loadCampaignsInWalletFailure,
    loadCampaignsInWalletSuccess,
    loadHistory,
    loadHistoryFailure,
    loadHistorySuccess,
    loadMerchants,
    loadMerchantsFailure,
    loadMerchantsSuccess,
    loadOrderDetails,
    loadOrderDetailsFailure,
    loadOrderDetailsSuccess,
    loadOrders,
    loadOrderSettings,
    loadOrderSettingsFailure,
    loadOrderSettingsSuccess,
    loadOrdersFailure,
    loadOrdersSuccess,
    loadTransactionDetails,
    loadTransactionDetailsFailure,
    loadTransactionDetailsSuccess,
    loadTransactions,
    loadTransactionsFailure,
    loadTransactionsSuccess,
    loadVouchersInWallet,
    loadVouchersInWalletFailure,
    loadVouchersInWalletSuccess,
    setActiveCampaign,
    setCurrentUser,
} from './brainlane-vouchers.actions';

export interface BrainlaneVouchersState {
    currentUser: ResultState<GetUserDto>;
    campaignsInWallet: ResultState<WalletCampaignListDto>;
    currentCampaign: string | null;
    voucherInfo: ResultState<BrainlaneVoucherDto>;
    convertingVoucher: ResultState<null>;
    vouchers: ResultState<WalletVoucherListDto>;
    history: ResultState<WalletHistoryDto>;
    orderSettings: ResultState<OrderSettingsDto>;
    orderCreation: ResultState<WalletOrderDetailsDto>;
    paymentLink: ResultState<GetOrderPaymentLinkResponseDto>;
    orders: ResultState<WalletOrderDto[]>;
    orderDetails: ResultState<WalletOrderDetailsDto>;
    transactions: ResultState<WalletTransactionDto[]>;
    transactionDetails: ResultState<WalletTransactionDetailsDto>;
    merchants: ResultState<MerchantListDto>;
}

export const initialState: BrainlaneVouchersState = {
    currentUser: initialStateResult,
    campaignsInWallet: initialStateResult,
    currentCampaign: null,
    voucherInfo: initialStateResult,
    convertingVoucher: initialStateResult,
    vouchers: initialStateResult,
    history: initialStateResult,
    orderSettings: initialStateResult,
    orderCreation: initialStateResult,
    paymentLink: initialStateResult,
    orders: initialStateResult,
    orderDetails: initialStateResult,
    transactions: initialStateResult,
    transactionDetails: initialStateResult,
    merchants: initialStateResult,
};

const brainlaneVouchersReducer = createReducer(
    initialState,
    on(
        loadCampaignsInWallet,
        (state): BrainlaneVouchersState => ({
            ...state,
            campaignsInWallet: stateLoading(state.campaignsInWallet.result),
        }),
    ),
    on(
        loadCampaignsInWalletSuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            campaignsInWallet: stateSuccess(data),
        }),
    ),
    on(
        loadCampaignsInWalletFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            campaignsInWallet: stateError(error, state.campaignsInWallet.result),
        }),
    ),
    on(
        setActiveCampaign,
        (state, { id }): BrainlaneVouchersState => ({
            ...state,
            currentCampaign: id,
        }),
    ),
    on(
        loadVouchersInWallet,
        (state): BrainlaneVouchersState => ({
            ...state,
            vouchers: stateLoading(state.vouchers.result),
        }),
    ),
    on(
        loadVouchersInWalletSuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            vouchers: stateSuccess(data),
        }),
    ),
    on(
        loadVouchersInWalletFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            vouchers: stateError(error, state.vouchers.result),
        }),
    ),
    on(
        getVoucherInfo,
        (state): BrainlaneVouchersState => ({
            ...state,
            voucherInfo: stateLoading(initialState.voucherInfo.result),
        }),
    ),
    on(
        getVoucherInfoSuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            voucherInfo: stateSuccess(data),
        }),
    ),
    on(
        getVoucherInfoFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            voucherInfo: stateError(error, initialState.voucherInfo.result),
        }),
    ),
    on(
        convertVoucher,
        (state): BrainlaneVouchersState => ({
            ...state,
            convertingVoucher: stateLoading(initialState.convertingVoucher.result),
        }),
    ),
    on(
        convertVoucherSuccess,
        (state): BrainlaneVouchersState => ({
            ...state,
            convertingVoucher: stateSuccess(null),
        }),
    ),
    on(
        convertVoucherFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            convertingVoucher: stateError(error, initialState.convertingVoucher.result),
        }),
    ),
    on(
        loadHistory,
        (state): BrainlaneVouchersState => ({
            ...state,
            history: stateLoading(initialState.history.result),
        }),
    ),
    on(
        loadHistorySuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            history: stateSuccess(data),
        }),
    ),
    on(
        loadHistoryFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            history: stateError(error, state.history.result),
        }),
    ),
    on(
        loadOrderSettings,
        (state): BrainlaneVouchersState => ({
            ...state,
            orderSettings: stateLoading(initialState.orderSettings.result),
        }),
    ),
    on(
        loadOrderSettingsSuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            orderSettings: stateSuccess(data),
        }),
    ),
    on(
        loadOrderSettingsFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            orderSettings: stateError(error, state.orderSettings.result),
        }),
    ),
    on(
        createOrder,
        (state): BrainlaneVouchersState => ({
            ...state,
            orderCreation: stateLoading(initialState.orderCreation.result),
        }),
    ),
    on(
        createOrderSuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            orderCreation: stateSuccess(data),
        }),
    ),
    on(
        createOrderFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            orderCreation: stateError(error, state.orderCreation.result),
        }),
    ),
    on(
        getOrderPaymentLink,
        (state): BrainlaneVouchersState => ({
            ...state,
            paymentLink: stateLoading(initialState.paymentLink.result),
        }),
    ),
    on(
        getOrderPaymentLinkSuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            paymentLink: stateSuccess(data),
        }),
    ),
    on(
        getOrderPaymentLinkFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            paymentLink: stateError(error, state.paymentLink.result),
        }),
    ),
    on(
        loadOrders,
        (state): BrainlaneVouchersState => ({
            ...state,
            orders: stateLoading(initialState.orders.result),
        }),
    ),
    on(
        loadOrdersSuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            orders: stateSuccess(data),
        }),
    ),
    on(
        loadOrdersFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            orders: stateError(error, state.orders.result),
        }),
    ),
    on(
        loadOrderDetails,
        (state): BrainlaneVouchersState => ({
            ...state,
            orderDetails: stateLoading(initialState.orderDetails.result),
        }),
    ),
    on(
        loadOrderDetailsSuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            orderDetails: stateSuccess(data),
        }),
    ),
    on(
        loadOrderDetailsFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            orderDetails: stateError(error, state.orderDetails.result),
        }),
    ),
    on(
        loadTransactions,
        (state): BrainlaneVouchersState => ({
            ...state,
            transactions: stateLoading(state.transactions.result),
        }),
    ),
    on(
        loadTransactionsSuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            transactions: stateSuccess(data),
        }),
    ),
    on(
        loadTransactionsFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            transactions: stateError(error, state.transactions.result),
        }),
    ),
    on(
        loadTransactionDetails,
        (state): BrainlaneVouchersState => ({
            ...state,
            transactionDetails: stateLoading(initialState.transactionDetails.result),
        }),
    ),
    on(
        loadTransactionDetailsSuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            transactionDetails: stateSuccess(data),
        }),
    ),
    on(
        loadTransactionDetailsFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            transactionDetails: stateError(error, initialState.transactionDetails.result),
        }),
    ),
    on(
        loadMerchants,
        (state): BrainlaneVouchersState => ({
            ...state,
            merchants: stateLoading(initialState.merchants.result),
        }),
    ),
    on(
        loadMerchantsSuccess,
        (state, { data }): BrainlaneVouchersState => ({
            ...state,
            merchants: stateSuccess(data),
        }),
    ),
    on(
        loadMerchantsFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            merchants: stateError(error, initialState.merchants.result),
        }),
    ),
    on(
        createAccount,
        (state): BrainlaneVouchersState => ({
            ...state,
            currentUser: stateLoading(initialState.currentUser.result),
        }),
    ),
    on(
        createAccountSuccess,
        (state, action): BrainlaneVouchersState => ({
            ...state,
            currentUser: stateSuccess(action.payload),
        }),
    ),
    on(
        createAccountFailure,
        (state, { error }): BrainlaneVouchersState => ({
            ...state,
            currentUser: stateError(error, initialState.currentUser.result),
        }),
    ),
    on(
        setCurrentUser,
        (state, action): BrainlaneVouchersState => ({
            ...state,
            currentUser: stateSuccess(action.payload),
        }),
    ),
);

export const vouchersFeature = createFeature({
    name: 'vouchers',
    reducer: brainlaneVouchersReducer,
});
