import {
    BrainlaneVoucherDto,
    CreateEmailCodeDto,
    CreateOrderDto,
    CreateUserDto,
    GetOrderPaymentLinkResponseDto,
    GetUserDto,
    MerchantListDto,
    OrderSettingsDto,
    WalletCampaignListDto,
    WalletHistoryDto,
    WalletOrderDetailsDto,
    WalletOrderDto,
    WalletTransactionDetailsDto,
    WalletTransactionDto,
    WalletVoucherListDto,
} from '@mbp/mbp-brainlane-vouchers-api-client';
import { createAction, props } from '@ngrx/store';

const prefix = '[Brainlane Vouchers]';

export const loadCampaignsInWallet = createAction(`${prefix} Load campaigns`);
export const loadCampaignsInWalletSuccess = createAction(
    `Load campaigns Success`,
    props<{ data: WalletCampaignListDto }>(),
);
export const loadCampaignsInWalletFailure = createAction(
    `${prefix} Load campaigns  Failure`,
    props<{
        error: string;
    }>(),
);

export const setActiveCampaign = createAction(`${prefix} Set active campaign`, props<{ id: string }>());

export const loadVouchersInWallet = createAction(`${prefix} Load vouchers`, props<{ campaignId: string }>());
export const loadVouchersInWalletSuccess = createAction(
    `Load vouchers Success`,
    props<{ data: WalletVoucherListDto }>(),
);
export const loadVouchersInWalletFailure = createAction(`${prefix} Load vouchers Failure`, props<{ error: string }>());

export const getVoucherInfo = createAction(`${prefix} Get voucher info`, props<{ code: string }>());
export const getVoucherInfoSuccess = createAction(
    `${prefix} Get voucher info Success`,
    props<{
        data: BrainlaneVoucherDto;
    }>(),
);
export const getVoucherInfoFailure = createAction(`${prefix} Get voucher info Failure`, props<{ error: string }>());

export const convertVoucher = createAction(`${prefix} Convert voucher`, props<{ code: string }>());
export const convertVoucherSuccess = createAction(`${prefix} Convert voucher Success`);
export const convertVoucherFailure = createAction(`${prefix} Convert voucher Failure`, props<{ error: string }>());

export const loadHistory = createAction(`${prefix} Load history`);
export const loadHistorySuccess = createAction(`${prefix} Load history Success`, props<{ data: WalletHistoryDto }>());
export const loadHistoryFailure = createAction(`${prefix} Load history Failure`, props<{ error: string }>());

export const loadOrderSettings = createAction(`${prefix} Load order settings`);
export const loadOrderSettingsSuccess = createAction(
    `Load order settings Success`,
    props<{ data: OrderSettingsDto }>(),
);
export const loadOrderSettingsFailure = createAction(
    `${prefix} Load order settings Failure`,
    props<{
        error: string;
    }>(),
);

export const loadOrders = createAction(`${prefix} Load orders`);
export const loadOrdersSuccess = createAction(`${prefix} Load orders Success`, props<{ data: WalletOrderDto[] }>());

export const loadOrdersFailure = createAction(`${prefix} Load orders Failure`, props<{ error: string }>());
export const loadOrderDetails = createAction(`${prefix} Load order details`, props<{ orderId: string }>());

export const loadOrderDetailsSuccess = createAction(
    `Load order details Success`,
    props<{ data: WalletOrderDetailsDto }>(),
);
export const loadOrderDetailsFailure = createAction(`${prefix} Load order details Failure`, props<{ error: string }>());

export const createOrder = createAction(`${prefix} Create order`, props<{ order: CreateOrderDto }>());
export const createOrderSuccess = createAction(
    `${prefix} Create order Success`,
    props<{
        data: WalletOrderDetailsDto;
    }>(),
);
export const createOrderFailure = createAction(`${prefix} Create order Failure`, props<{ error: string }>());

export const getOrderPaymentLink = createAction(`${prefix} Get order payment link`, props<{ orderId: string }>());
export const getOrderPaymentLinkSuccess = createAction(
    `Get order payment link succes`,
    props<{ data: GetOrderPaymentLinkResponseDto }>(),
);
export const getOrderPaymentLinkFailure = createAction(
    `${prefix} Get order payment link failure`,
    props<{
        error: string;
    }>(),
);

export const loadTransactions = createAction(`${prefix} Load transactions`, props<{ campaignId?: string }>());
export const loadTransactionsSuccess = createAction(
    `Load transactions Success`,
    props<{ data: WalletTransactionDto[] }>(),
);
export const loadTransactionsFailure = createAction(`${prefix} Load transactions Failure`, props<{ error: string }>());

export const loadTransactionDetails = createAction(
    `Load transaction details`,
    props<{ transactionId: string; campaignId?: string }>(),
);
export const loadTransactionDetailsSuccess = createAction(
    `Load transaction details Success`,
    props<{ data: WalletTransactionDetailsDto }>(),
);
export const loadTransactionDetailsFailure = createAction(
    `Load transaction details Failure`,
    props<{ error: string }>(),
);

export const loadMerchants = createAction(`${prefix} Load merchants`, props<{ campaignId: string }>());
export const loadMerchantsSuccess = createAction(
    `${prefix} Load merchants success`,
    props<{
        data: MerchantListDto;
    }>(),
);
export const loadMerchantsFailure = createAction(`${prefix} Load merchants failure`, props<{ error: string }>());

export const sendVerificationCode = createAction(
    `${prefix} Send verification code`,
    props<{ payload: CreateEmailCodeDto }>(),
);
export const sendVerificationCodeSuccess = createAction(`${prefix} Send verification code success`);
export const sendVerificationCodeFailure = createAction(
    `${prefix} Send verification code failure`,
    props<{
        error: string;
    }>(),
);

export const createAccount = createAction(`${prefix} Create account`, props<{ payload: CreateUserDto }>());
export const createAccountSuccess = createAction(`${prefix} Create account success`, props<{ payload: GetUserDto }>());
export const createAccountFailure = createAction(`${prefix} Create account failure`, props<{ error: string }>());

export const setCurrentUser = createAction(`${prefix} Set current user`, props<{ payload: GetUserDto }>());
